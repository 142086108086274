import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { ComponentRef, Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import { AlertComponent } from '@core/components/alert/alert.component';
import { ConfirmComponent } from '@core/components/confirm/confirm.component';
import { AlertOptions } from '@core/interfaces/alert-options';
import { Closable } from '@core/interfaces/closable';
import { ConfirmOptions } from '@core/interfaces/confirm-options';
import { FADE_DURATION } from '@shared/utils/animations';

@Injectable({ providedIn: 'root' })
export class ModalService {
  private overlayRefs: Map<ComponentType<any>, OverlayRef> = new Map();

  constructor(private overlay: Overlay) {}

  isModalOpen() {
    return this.overlayRefs.size > 0;
  }

  open<T extends Closable>(component: ComponentType<T>, data?: any) {
    if (this.overlayRefs.has(component)) {
      return; // If an overlay is open, do nothing
    }

    const overlayRef = this.overlay.create({
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-dark-backdrop',
      positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically()
    });

    overlayRef.backdropClick().subscribe(() => this.close(component));

    const portal = new ComponentPortal(component);
    const componentRef = overlayRef.attach(portal) as ComponentRef<T>;
    const result$ = new Subject<any>();
    if (data) Object.assign(componentRef.instance, data);

    componentRef.instance.close = () => {
      componentRef.instance.state = 'void';

      setTimeout(() => {
        this.close(component);
        result$.complete();
      }, FADE_DURATION);
    };

    componentRef.instance.result = result$;
    this.overlayRefs.set(component, overlayRef);
    return result$;
  }

  close(component: ComponentType<any>) {
    const overlayRef = this.overlayRefs.get(component);
    if (overlayRef) {
      overlayRef.dispose();
      this.overlayRefs.delete(component);
    }
  }

  alert(options: AlertOptions = {}) {
    const defaultOptions: AlertOptions = {
      duration: 3000,
      icon: 'success',
      message: '',
      title: 'Alert'
    };

    const alertOptions = {
      ...defaultOptions,
      ...options
    };

    return this.open(AlertComponent, alertOptions);
  }

  confirm(options: ConfirmOptions = {}) {
    const defaultOptions: ConfirmOptions = {
      cancelText: 'No',
      confirmText: 'Yes',
      message: 'Are you sure?',
      title: 'Confirm'
    };

    const confirmOptions = {
      ...defaultOptions,
      ...options
    };

    return this.open(ConfirmComponent, confirmOptions);
  }
}
